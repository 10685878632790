import getTarget from 'javascripts/utils/get-target';

document.addEventListener('mousedown', (event) => {
  const $target = getTarget(event.target, '.checkbox__label .link, .radio__label .link');

  if ($target) {
    event.preventDefault();
  }
});

document.addEventListener('click', (event) => {
  const $target = getTarget(event.target, '.checkbox__label .link, .radio__label .link');

  if ($target) {
    event.stopPropagation();
  }
});
